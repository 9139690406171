.block-3-cols {
    font-family: var(--font-sans);
    line-height: var(--line-height-sans);
    font-size: var(--font-size-base);
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto;
    grid-gap: 2rem;
    line-height: 1.2;
    margin: 2.5rem 0
}

.block-3-cols,
.block-3-cols bold,
.block-3-cols strong {
    font-weight: 200
}

.block-3-cols h2 {
    font-family: var(--font-mono);
    margin: 0 0 .4rem;
    font-size: var(--font-size-xxsmall)
}

.block-3-cols ul {
    list-style: none;
    padding: 0;
    margin: 0 0 1rem
}

.block-3-cols ul a {
    outline: 0;
    touch-action: manipulation;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: var(--black);
    text-decoration: none;

    .nota {
        font-family: var(--font-mono);
        font-size: var(--font-size-xxsmall);
        font-weight: 700;
    }

    &::after {
        margin-left: .3em;
        content: "↗";
        opacity: 0;
    }
}

.awards a {
    display: block;
    position: relative;
    padding-left: 2.2rem;

    span {
        display: block;
        font-family: var(--font-mono);
        font-weight: 600;
        position: absolute;
        top: -0.13em;
        left: 0;
        width: 1.8rem;
        text-align: left;
    }
}

.__accessible .block-3-cols ul a:focus {
    outline: 2px dashed!important
}

.block-3-cols ul a:hover:after {
    opacity: 1;
}

@media (max-width:480px) {
    .block-3-cols {
        grid-template-columns: 100%;
        grid-gap: .2rem;
    }
}