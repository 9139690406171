:root {
    --white: #d7dedb;
    --black: #000;
  --grey: #999;

  --primary: var(--black);
  --secondary: var(--white);

  --assertive: #ff00ff;
  --focus: #ff00ff;
  --color-error: #ff0000;
  --color-sucess: #4dc247;
}

.palette-primary {
    --primary: var(--black);
    --secondary: var(--grey);
}

.palette-black {
    --primary: var(--white);
    --secondary: var(--black);
}

.palette-red {
    --primary: var(--black);
    --secondary: var(--color-error);
}



	
	