html{
    width: 100%;
    height: 100%;
    background: var(--secondary);
}

body{
    width: 100%;
    min-height: 100%;
    padding: var(--padding-xs) var(--padding-m);

    main {
        width: 100%;
    }

    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

