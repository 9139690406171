.img-subtitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 6rem;

    @media (max-width:480px) {
        margin: 20px 0 60px;
        display: block;

        > img {
            width: 100%;
            height: auto;
        }
    }
}