#Footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    
    > svg {
        max-width: 150px;
        height: auto
    }
}