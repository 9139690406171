.block-3-kit-digital {
    font-family: var(--font-sans);
    line-height: var(--line-height-sans);
    font-size: var(--font-size-base);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 2%;
    line-height: 1.2;
    margin: 2.5rem 0
}

.block-3-kit-digital .column {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--black);
    border-radius: 8px;
    padding: var(--padding-m);
    h2,
    h3,
    h4 {
        font-family: var(--font-mono);
        margin: 0 0 .6rem;
        font-size: var(--font-size-medium);
    }
    h1 {
        font-size: 2em;
    }
    h2 {
        font-size: 1.5em;
        margin-bottom: 1em;
    }
    h3 {
        font-size: 1.17em;
        margin-bottom: 1.5rem;
        text-decoration: underline;
    }
    h4 {
        font-size: 1em;
        margin-top: 1.5rem;
        font-weight: 900;
    }
    p {
        margin: .5rem 0;
    }
    li {
        margin: 2rem 0;
    }
}

.block-3-kit-digital strong {
    font-weight: 600
}

.block-3-kit-digital ul {
    list-style: disc;
    padding: 0;
    margin: 1rem;
}

.block-3-kit-digital ul a {
    outline: 0;
    touch-action: manipulation;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: var(--black);
    text-decoration: none
}

.__accessible .block-3-kit-digital ul a:focus {
    outline: 2px dashed!important
}

.block-3-kit-digital ul a:hover:before {
    margin-right: .3rem;
    content: "↗"
}

@media (max-width:600px) {
    .block-3-kit-digital {
        grid-template-columns: 100%;
        font-size: 2rem;
    }
}

hr {
    width: 100%;

    /* FIX */
    /*border-style: solid;
    color: var(--grey);
    border-top: 1px;
    
    border-top solo funciona metiendo todos los atributos
    En tu caso para poner solo el tamaño tendria que ser border-top-width
    */
    
    border-top: 2px solid var(--grey);
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
}

.reference-text {
    font-size: .6em;
    color: var(--black);
    margin: 0 0 1rem;
}